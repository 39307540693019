<template>
  <apexchart v-if="activeChart" ref="pilotChartLimit" type="area" :height="height" :options="chartOptions" :series="chartSeries" />
</template>

<script>
let clickTiming = null
export default {
  name: 'ChartLimit',
  props: {
    height: {
      type: String,
      default: '400px'
    },
    chartid: {
      type: String,
      default: 'pilot-logbook'
    },
    annotationLimit: {
      type: Number,
      default: 0
    },
    annotation: {
      type: String,
      default: ''
    },
    annotationText: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 0
    },
    categories: {
      type: Array,
      default: () => []
    },
    series: {
      type: Array,
      default: () => []
    }
  },
  data: (vm) => ({
    activeChart: false,
    chartOptions: {
      id: 'pilotChartLimit',
      chart: {
        foreColor: '#F9F9F9',
        stacked: false,
        toolbar: {
          show: false
        },
        events: {
          click: vm.ChartClick
        },
        zoom: {
          enabled: false
        }
      },
      tooltip: {
        x: {
          format: 'd MMM yyyy'
        },
        y: {
          formatter: vm.labelFormat,
          title: {
            formatter: (seriesName) => seriesName
          }
        }
      },
      grid: {
        show: false
      },
      legend: {
        fontSize: '12px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 400,
        position: 'top',
        horizontalAlign: 'center',
        itemMargin: {
          horizontal: 20,
          vertical: 8
        },
        markers: {
          width: 10,
          height: 10
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.8,
          opacityTo: 0.2,
          stops: [0, 90, 100]
        }
      },
      stroke: {
        width: 2,
        curve: 'smooth',
        colors: ['#22CCEE', '#FFA500']
      },
      annotations: {
        xaxis: [
          {
            x: new Date(vm.$moment().startOf('day').add(7, 'hours').format('DD MMM YYYY HH:mm:ss')).getTime(),
            borderColor: '#F9F9F9',
            strokeDashArray: 0.5,
            label: {
              borderColor: '#F9F9F9',
              style: {
                fontSize: '10px',
                color: '#162447',
                background: '#F9F9F9'
              },
              orientation: 'horizontal',
              offsetY: -10,
              text: 'Today'
            }
          }
        ],
        yaxis: [
          {
            y: 0,
            borderColor: '#E33F5A',
            strokeDashArray: 0.35,
            label: {
              borderColor: '#E33F5A',
              style: {
                fontSize: '10px',
                color: '#F9F9F9',
                background: '#E33F5A'
              },
              text: 'Limit'
            }
          }
        ]
      },
      xaxis: {
        type: 'datetime',
        categories: [],
        labels: {
          style: {
            fontSize: '10px',
            fontFamily: 'Poppins, sans-serif'
          }
        }
      },
      yaxis: {
        min: 0,
        max: 10,
        opposite: true,
        labels: {
          style: {
            fontSize: '10px',
            fontFamily: 'Poppins, sans-serif'
          },
          formatter: (v) => {
            return v.toFixed(0) + 'h'
          }
        }
      }
    },
    chartSeries: []
  }),
  watch: {
    series: {
      deep: true
    }
  },
  created () {
    this.activeChart = false
    this.chartOptions.chart.id = this.chartid
    this.chartOptions.yaxis.max = this.limit
    this.chartOptions.annotations.yaxis[0].y = this.annotationLimit
    this.chartOptions.xaxis.categories = this.categories || []
  },
  mounted () {
    setTimeout(() => {
      this.BindSeries()
      this.activeChart = true
    }, 150)
  },
  methods: {
    BindSeries () {
      const ModifySeries = [Object.assign({}, this.series[0]), Object.assign({}, this.series[1])]
      this.chartSeries = [].concat(ModifySeries)
    },
    ClearLegend () {
      const series6h = this.series.find(r => r.name === '6h')
      const series35h = this.series.find(r => r.name === '35h')
      if (series6h) {
        this.$refs.pilotChartLimit.hideSeries('6h')
      } else {
        this.$refs.pilotChartLimit.hideSeries('9h')
      }
      if (series35h) {
        this.$refs.pilotChartLimit.hideSeries('35h')
      } else {
        this.$refs.pilotChartLimit.hideSeries('40h')
      }
      this.$refs.pilotChartLimit.hideSeries('100h')
      this.$refs.pilotChartLimit.hideSeries('300h')
      this.$refs.pilotChartLimit.hideSeries('1050h')
    },
    ChartClick (chart, seriesIndex, opts) {
      if (clickTiming) {
        clearTimeout(clickTiming)
      }
      const el = chart.target
      if (el) {
        if (el.innerHTML === '6h' || el.innerHTML === '9h' || el.innerHTML === '35h' || el.innerHTML === '40h' || el.innerHTML === '100h' || el.innerHTML === '300h' || el.innerHTML === '1050h') {
          this.ClearLegend()
          clickTiming = setTimeout(() => {
            this.$refs.pilotChartLimit.showSeries(el.innerHTML)
          }, 100)
        }
      }
    },
    labelFormat (value) {
      return value + 'h'
    }
  }
}
</script>
<style lang="scss">
  @import './../assets/scss/global-vars';

  .apexcharts-xaxistooltip{
    padding: 4px 8px;
    background-color: $danger;
    color: $white;
    border: 0;
    border-radius: 10px;
    &::before,
    &::after{
      border-bottom-color: $danger;
      color: $danger;
    }
  }
</style>
